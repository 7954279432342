import {useMutation, useQuery} from '@apollo/client';
import {isNullOrUndefinedOrEmpty} from '@eon.cz/apollo13-frontend-common';
import {Mutation, Query, UcetSpravceZmocnenecTypPristupu, UcetTyp, UctyMutationRateMobileAppArgs} from '@eon.cz/apollo13-graphql';
import Grid from '@mui/material/Grid2';
import dynamic from 'next/dynamic';
import {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Store} from '../../../Store';
import {useCommonAction} from '../../Common/actions/CommonActions';
import {PageContext} from '../../Common/contexts/PageContext';
import {isProdPreprod, sendDataToRN} from '../../Common/services/CommonService';
import {getFromLS, saveToLS} from '../../Common/services/PageService';
import {OdbernaMistaQueries} from '../../OdbernaMista/graphql';
import {OdbernaMistaService} from '../../OdbernaMista/service/OdbernaMistaService';
import {UcetMutations} from '../../Ucet/graphql/UcetMutations';

const IndexPageZadatel = dynamic(() => import('./IndexPageZadatel').then((mod) => mod.IndexPageZadatel));
const IndexPageOstatni = dynamic(() => import('./IndexPageOstatni').then((mod) => mod.IndexPageOstatni));
const BiometryConfirmationDialog = dynamic(() => import('../../Mobile/components/BiometryConfirmationDialog').then((mod) => mod.BiometryConfirmationDialog));

const IndexPage = () => {
    // context
    const {me, komodita, loggedInRestrictedMode, canRateMobileApp, refetchCanRateMobileApp} = useContext(PageContext);

    // local state
    const [openBiometricsDialog, setOpenBiometricsDialog] = useState(false);

    // local variables
    const mobile = getFromLS('mobile');
    const isMobile = mobile?.isMobile;
    const rating = useSelector((state: Store) => state.common?.mobile?.rating);
    const action = useSelector((state: Store) => state.common?.mobile?.action);
    const canUseBiometrics = mobile.canUseBiometrics;
    const userId = mobile?.userId;
    const showBiometryConfirmation = isMobile && mobile?.biometryAvailable && isNullOrUndefinedOrEmpty(canUseBiometrics) && isNullOrUndefinedOrEmpty(userId);
    const isZadatel = me?.typ === UcetTyp.ZADATEL || me?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.ZADATEL;
    const isObchodnik = me?.typ === UcetTyp.OBCHODNIK;

    // methods
    const handleCloseBiometryDialog = () => setOpenBiometricsDialog(false);

    // actions
    const {setMobileProps} = useCommonAction();

    // queries
    useQuery<Query>(OdbernaMistaQueries.gql.getHDOs, {
        fetchPolicy: 'no-cache',
        skip: isProdPreprod && !isMobile,
        onCompleted: (data) => {
            const hdo = data.elektrina.hdos?.getHdos;
            const remap = hdo?.map((neco) => ({
                ean: neco?.ean,
                vlastniNazevOM: neco?.vlastniNazevOM,
                hdo: {
                    sazby: neco?.hdo.flatMap(
                        (n) =>
                            n?.sazby?.map((sazba) => ({
                                id: Math.floor(Math.random() * (1000000 - 1 + 1)) + 1,
                                dny: sazba?.dny?.map((den) => ({
                                    id: Math.floor(Math.random() * (1000000 - 1 + 1)) + 1,
                                    casy: den?.casy,
                                    denVTydnu: den?.denVTydnu,
                                })),
                                sazba: OdbernaMistaService.getNazevSazbyString(sazba?.sazba).trim(),
                            })) ?? [],
                    ),
                    endDate: neco?.hdo[0]?.endDate,
                },
            }));
            sendDataToRN({setWidgetData: remap});
        },
    });

    // mutations
    const [sendRateMobileApp] = useMutation<Mutation, UctyMutationRateMobileAppArgs>(UcetMutations.gql.sendRateMobileApp, {
        onCompleted: () => {
            setMobileProps({rating: undefined, action: undefined});
            saveToLS('mobile', {rating: undefined, action: undefined});
            refetchCanRateMobileApp();
        },
        onError: () => {
            setMobileProps({rating: undefined, action: undefined});
            saveToLS('mobile', {rating: undefined, action: undefined});
        },
    });

    // effects
    useEffect(() => {
        if (!isObchodnik && isMobile) {
            sendDataToRN({hideTabs: 'HIDE', userEmail: me?.email, userPhone: me?.telefon ?? '', canRateMobileApp});
        }
    }, [isObchodnik, isMobile, canRateMobileApp, me?.email, me?.telefon]);

    useEffect(() => {
        if (action && canRateMobileApp) {
            sendRateMobileApp({variables: {action, rating}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rating, action, canRateMobileApp]);

    if (showBiometryConfirmation && !openBiometricsDialog) {
        setOpenBiometricsDialog(true);
    }

    // If user not registered or is logged in restricted mode (groupe has certificate login) and user dont have the certificate in browser
    if (loggedInRestrictedMode || !me?.registrovany) {
        // No other agendas available in limited mode
        return <Grid container spacing={2} />;
    }

    if (isZadatel) {
        return (
            <>
                <IndexPageZadatel me={me} komodita={komodita} />
                {openBiometricsDialog && <BiometryConfirmationDialog open={openBiometricsDialog} onClose={handleCloseBiometryDialog} />}
            </>
        );
    }

    if (!isZadatel) {
        return (
            <>
                <IndexPageOstatni me={me} komodita={komodita} />
                {openBiometricsDialog && <BiometryConfirmationDialog open={openBiometricsDialog} onClose={handleCloseBiometryDialog} />}
            </>
        );
    }
};

export default IndexPage;
